import $ from 'jquery';
import 'slick-carousel';
import 'slick-carousel/slick/slick.css';
import animating from './libs/animating' //import the js file

import { gsap, TweenLite, TweenMax, MotionPathPlugin, ScrollToPlugin, ScrollTrigger } from "gsap/all";
gsap.registerPlugin(TweenLite, TweenMax, MotionPathPlugin, ScrollToPlugin, ScrollTrigger); 

const home = {};
const animatingElement = $(".animating-element") //element

window.$ = $

animating($,gsap)
.from({
  element: animatingElement,
  from: 'from-top', //animation type 'from-top','from-bottom','from-left','from-right','fade','swipe-horizontal','swipe-vertical'
  time: 1, // time in secnd
  ease: 'power3.easeOut' // easeing https://greensock.com/docs/v3/Eases
}),

home.sliderBanner = function(){
  let helpers = {
		addZeros: function (n) {
			return (n < 10) ? '0' + n : '' + n;
		}
	};
  const slider = $('.banner-home  .slider'),
  caps = slider.find(".list");
  slider.slick({
		arrows:true,
		dots:false,
		fade:true,
		autoplay: true,
  	autoplaySpeed: 4000,
    lazyload: "ondemand"
	});
  slider.on('afterChange', function(event, slick, currentSlide){
    $(this).parent().find('.numbers .active').html(helpers.addZeros(currentSlide + 1)); 
  });
  let sliderItemsNum = slider.find('.slick-slide').not('.slick-cloned').length;
  slider.parent().find('.numbers .total').html(helpers.addZeros(sliderItemsNum));


  if(slider.length){
    var bg = slider.find(".slick-list .slick-slide figure > img");

    TweenLite.set(caps.find('figcaption img'), {css: {'position': 'relative'}});
    TweenLite.set(caps.find('h1'), {css: {'position': 'relative'}});
    TweenLite.set(caps.find('p'), {css: {'position': 'relative'}});
    TweenLite.set(caps.find('a'), {css: {'position': 'relative'}});
  }
  slider.on('beforeChange', function(event, slick, currentSlide, nextSlide){
      var caption = slider.find(".slick-slide[data-slick-index=" + nextSlide + "]");
      var befores = slider.find(".slick-slide[data-slick-index=" + currentSlide + "]");

      TweenLite.to(caption.find('figcaption img'), 0.2, {css: {'top': '50px','opacity': '0',},ease: 'Quart.easeOut'});
      TweenLite.to(caption.find('h1'), 0.2, {css: {'top': '50px','opacity': '0',},ease: 'Quart.easeOut'});        
      TweenLite.to(caption.find('p'), 0.4, {css: {'top': '50px','opacity': '0',},ease: 'Quart.easeOut'});        
      TweenLite.to(caption.find('a'), 0.6, {css: {'top': '50px','opacity': '0',},ease: 'Quart.easeOut'});        
  });
  slider.on('afterChange', function(event, slick, currentSlide){
      var caption = slider.find(".slick-slide[data-slick-index=" + currentSlide + "]");

      TweenLite.to(caption.find('figcaption img'), 0.5, {css: {'top': '0','opacity': '1',},delay: 0.2,ease: 'Quart.easeOut'});
      TweenLite.to(caption.find('h1'), 0.5, {css: {'top': '0','opacity': '1',},delay: 0.4,ease: 'Quart.easeOut'});        
      TweenLite.to(caption.find('p'), 0.7, {css: {'top': '0','opacity': '1',},delay: 0.4,ease: 'Quart.easeOut'});        
      TweenLite.to(caption.find('a'), 0.9, {css: {'top': '0','opacity': '1',},delay: 0.4,ease: 'Quart.easeOut'});        
  });
}

home.sliderHighlight = function(){
	$('.slider-highlight').slick({
		arrows: true,
    dots: false,
    infinite: false,
    speed: 800,
    draggable: true,
    slidesToShow: 3.3,
    slidesToScroll: 1,
    lazyload: "ondemand",
    nextArrow: '<div class="arrow-next"> \
                    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg"> \
                      <g opacity="0.5"> \
                        <path d="M19.8333 11.6663L36.1666 27.9997L19.8333 44.333" stroke="#808690" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/> \
                      </g> \
                    </svg> \
                  </div>',
    prevArrow: '<div class="arrow-prev"> \
                    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg"> \
                      <g opacity="0.5"> \
                        <path d="M36.1667 11.6663L19.8334 27.9997L36.1667 44.333" stroke="#808690" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/> \
                      </g> \
                    </svg> \
                  </div>',
    responsive: [
      {
        breakpoint: 1281,
        settings: {
          slidesToShow: 2.6,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1.4,
        },
      },
    ],
	});
}

home.sliderNews = function(){
	$('.slider-news').slick({
		arrows: true,
    dots: false,
    infinite: true,
    speed: 800,
    draggable: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    lazyload: "ondemand",
    prevArrow: $('.slick-arrow.news .arrow-prev'),
    nextArrow: $('.slick-arrow.news .arrow-next'),
    responsive: [
      {
        breakpoint: 1281,
        settings: {
          nextArrow: '<div class="arrow-next"> \
                    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg"> \
                      <g opacity="0.5"> \
                        <path d="M19.8333 11.6663L36.1666 27.9997L19.8333 44.333" stroke="#808690" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/> \
                      </g> \
                    </svg> \
                  </div>',
          prevArrow: '<div class="arrow-prev"> \
                    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg"> \
                      <g opacity="0.5"> \
                        <path d="M36.1667 11.6663L19.8334 27.9997L36.1667 44.333" stroke="#808690" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/> \
                      </g> \
                    </svg> \
                  </div>',
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          fade: true,
          nextArrow: '<div class="arrow-next"> \
                    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg"> \
                      <g opacity="0.5"> \
                        <path d="M19.8333 11.6663L36.1666 27.9997L19.8333 44.333" stroke="#808690" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/> \
                      </g> \
                    </svg> \
                  </div>',
          prevArrow: '<div class="arrow-prev"> \
                    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg"> \
                      <g opacity="0.5"> \
                        <path d="M36.1667 11.6663L19.8334 27.9997L36.1667 44.333" stroke="#808690" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/> \
                      </g> \
                    </svg> \
                  </div>',
          slidesToShow: 1,
        },
      },
    ],
	});
}

home.scrolltop = function(){
	$('.homesec-1').click(function() {
    $('html, body').animate({
      scrollTop: $(".banner-home").offset().top -100
    }, 1000)
  }), 
  $('.homesec-2').click(function (){
    $('html, body').animate({
      scrollTop: $(".about-home").offset().top -100
    }, 1000)
  }),
  $('.homesec-3').click(function (){
    $('html, body').animate({
      scrollTop: $(".product-highlight-home").offset().top -100
    }, 1000)
  }),
  $('.homesec-4').click(function (){
    $('html, body').animate({
      scrollTop: $(".news-home").offset().top -100
    }, 1000)
  })
}

home.init = function(){
	this.sliderBanner()
	this.sliderHighlight()
	this.sliderNews()
	this.scrolltop()
}

home.init()

// $(document).ready(() => {
//   header($).init()
// })
